/* pages/css/Recharge.css */

.recharge-container {
  max-width: 960px;
  margin: 0 auto;
}

.payment-icons i {
  font-size: 2rem;
  margin: 0 10px;
  color: #333;
}

.package-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.package-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.package-card .card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.package-card .price {
  font-size: 1.25rem;
  color: #4caf50;
  margin-bottom: 0.5rem;
}

.package-card .credits {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1rem;
}

.package-card .btn {
  width: 100%;
  font-size: 1rem;
}
